import React from "react";
import { Helmet } from "react-helmet";
import PageWrapper from "../components/PageWrapper";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  card: {
    margin: theme.spacing(2),
    height: "100%",
    overflow: "scroll",
  },
  media: {
    height: 140,
  },
  actions: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    overflow: "visible",
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",

    margin: "auto",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    // "& > *": {
    //   marginBlock: "1rem",
    // },
    // display: "flex",
    // alignItems: "center",
    // justifyContent: "center",
    // padding: theme.spacing(2, 4, 3),
    minHeight: "1vh",
    paddingTop: "7vh",
  },
}));
const BlogPost = ({ pageContext }) => {
  const { post } = pageContext;
  const classes = useStyles();

  const correctedContent = post.content.replace(
    /<img/g,
    '<img style="width: 100%; height: auto;"'
  );
  const date = new Date(post.date);
  const readableDate = date.toLocaleString();

  // console.log(correctedContent);
  

  return (
    <>
      <Helmet title="Blog | Genify" defer={false} />
      <PageWrapper
        headerConfig={{
          theme: "light",
          align: "right",
          variant: "primary",
          isFluid: true,
          source: "genify",
          button: "null", // cta, account, null
          path: "blogPage",
        }}
        footerConfig={{
          style: "style1", //style1, style2
        }}
      >
        <div className={classes.paper}>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              textAlign: "center",
              flexDirection: "column",
              flex: "center",
            }}
          >
            <img
              src={post && post.featured_image}
              alt={post.title}
              style={{
                width: "100%",
                maxHeight: "70vh",
                objectPosition: "center 60%",
                // maxWidth: "500px",
                objectFit: "cover",
                borderTopLeftRadius: "10px",
                borderTopRightRadius: "10px",
                margin: "auto",
                marginBottom: "10px",
              }}
            />

            {/* <p
              style={{
                fontSize: "12px",
                color: "#3078ff",
                margin: "0 0 20px 0",
                fontWeight: "500",
              }}
            >
              Blog Post
            </p> */}
            {/* <h2 style={{ padding: "10vw" }}>{post.title}</h2> */}
            <p
              style={{
                fontSize: "14px",
                color: "#9b9b9b",
                // margin: "0 0 20px 0",
                marginBlock: "20px",
                textAlign: "left",
                paddingLeft: "10vw",
              }}
            >
              {`By ${post.author && post.author["name"]} on  ${readableDate}`}
            </p>
          </div>
          <div
            style={{
              margin: "0 10vw 10vh",
              display: "flex",
              flexDirection: "column",
              gap: "20px",
            }}
            dangerouslySetInnerHTML={{
              __html: correctedContent,
            }}
          ></div>
        </div>
      </PageWrapper>
    </>
  );
};

export default BlogPost;
